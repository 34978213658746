export default {
	data: {
		selectedImageIndex: undefined,
		openstreetmap: false
	},
	beforeMount: function () {

		const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
		var cookieName = 'allowOpenstreetmap_' + suffix;
		var allowOpenstreet = localStorage.getItem(cookieName);
		if (allowOpenstreet) {
			this.openstreetmap = true;
		}

		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['website', 'v-office.com']).join('@');
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		}
	},
	methods: {


		setCookie: function (name) {
			localStorage.setItem(name, true);
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		unitImages: function (string) {
			//console.log(string);
		}
	},

};