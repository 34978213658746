import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units1: [],
			units2: [],
			units3: [],
			count1: null,
			count2: null,
			count3: null
		};
	},

	mounted: function () {
		this.observeVisibility();
	},

	beforeMount: function () {
		var results1 = this.$el.getElementsByClassName('unit-list-section1');
		if (results1.length) {
			this.resultFields = findResultFields(results1[0].innerHTML, this.$el);
		}

	},
	updated: function () {
		this.applySlider1();
		this.applySlider2();
		this.applySlider3();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {

		toogleTabs: function (value) {
			var other1 = null;
			var other2 = null;
			if (value == 1) {
				other1 = 2;
				other2 = 3;
			} else if (value == 2) {
				other1 = 1;
				other2 = 3;
			} else if (value == 3) {
				other1 = 1;
				other2 = 2;
			}

			$('.tab').removeClass('active');

			$('.tab' + value).addClass('active');

			$('#similarsection' + value).css({
				'display': 'block',
				'z-index': '3',
				'visibility': 'visible'
			});


			$('#similarsection' + other1).css({
				'display': 'none',
				'z-index': '0',
				'visibility': 'hidden'
			});
			$('#similarsection' + other2).css({
				'display': 'none',
				'z-index': '0',
				'visibility': 'hidden'
			});
		},

		observeVisibility() {
			const options = {
				root: null,
				rootMargin: '0px 0px 0px 0px',
				threshold: 0 // Einstellen Sie den Schwellenwert basierend auf Ihren Anforderungen
			};

			const callback = (entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						this.search4similar1();
						this.search4similar2();
						this.search4similar3();
						observer.unobserve(entry.target);
					}
				});
			};

			const observer = new IntersectionObserver(callback, options);
			observer.observe(document.getElementById('similarunits'));
		},

		search4similar1: function () {
			//console.log('Ich lege jetzt los!!!');

			var beds = 2;
			let el = document.querySelector("[data-beds]");
			if (el) {
				beds = parseInt(el.getAttribute('data-beds'), 10);
			} else {
				console.log('element beds is not found..................');
			}
			var unitId;
			let e2 = document.querySelector("[data-unit-id]");
			if (e2) {
				unitId = parseInt(e2.getAttribute('data-unit-id'));
				//console.log('Unit id int:' + unitId);
			}
			var req;

			if (beds) {
				req = {
					fields: this.resultFields,
					filter: {
						'beds': beds
					},
					max: 6,
					sorting: 'random'
				};

				doReq('searchUnits', req)
					.then((res) => {
						var foundUnits = [];
						if (res.units.length) {
							for (let i = 0; i < res.units.length; i++) {
								if (res.units[i]._id !== unitId) {
									foundUnits.push(res.units[i]);
								}

							}
						}
						this.count1 = foundUnits.length;
						this.units1 = foundUnits;


					});
			}
		},

		search4similar2: function () {
			var region = null;
			let el = document.querySelector("[data-region-id]");
			if (el) {
				region = parseInt(el.getAttribute('data-region-id'), 10);
			} else {
				console.log('element region is not found..................');
			}
			var unitId;
			let e2 = document.querySelector("[data-unit-id]");
			if (e2) {
				unitId = parseInt(e2.getAttribute('data-unit-id'));
				//console.log('Unit id int:' + unitId);
			}
			var req;

			if (region) {
				req = {
					fields: this.resultFields,
					filter: {
						'region.id': region
					},
					max: 6,
					sorting: 'random'
				};

				doReq('searchUnits', req)
					.then((res) => {
						var foundUnits = [];
						if (res.units.length) {
							for (let i = 0; i < res.units.length; i++) {
								if (res.units[i]._id !== unitId) {
									foundUnits.push(res.units[i]);
								}

							}
						}
						this.count2 = foundUnits.length;
						this.units2 = foundUnits;


					});
			}
		},
		search4similar3: function () {
			var street = null;
			let el = document.querySelector("[data-street-name]");
			if (el) {
				street = el.getAttribute('data-street-name');
			} else {
				console.log('element street is not found..................');
			}
			var unitId;
			let e2 = document.querySelector("[data-unit-id]");
			if (e2) {
				unitId = parseInt(e2.getAttribute('data-unit-id'));
				//console.log('Unit id int:' + unitId);
			}
			var req;

			if (street) {
				req = {
					fields: this.resultFields,
					filter: {
						'address.street': street
					},
					max: 6,
					sorting: 'random'
				};

				doReq('searchUnits', req)
					.then((res) => {
						var foundUnits = [];
						if (res.units.length) {
							for (let i = 0; i < res.units.length; i++) {
								if (res.units[i]._id !== unitId) {
									foundUnits.push(res.units[i]);
								}

							}
						}
						this.count3 = foundUnits.length;
						this.units3 = foundUnits;


					});
			}
		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},

		applySlider1: function () {
			$(".owl-carousel.similar1").owlCarousel({
				loop: false,
				margin: VOFFICE.settings.olwCarouselMargin,
				smartSpeed: 1000,
				autoplay: false,
				nav: VOFFICE.settings.olwCarouselNav,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					993: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		},
		applySlider2: function () {
			$(".owl-carousel.similar2").owlCarousel({
				loop: false,
				margin: VOFFICE.settings.olwCarouselMargin,
				smartSpeed: 1000,
				autoplay: false,
				nav: VOFFICE.settings.olwCarouselNav,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					993: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		},
		applySlider3: function () {
			$(".owl-carousel.similar3").owlCarousel({
				loop: false,
				margin: VOFFICE.settings.olwCarouselMargin,
				smartSpeed: 1000,
				autoplay: false,
				nav: VOFFICE.settings.olwCarouselNav,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					993: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};