var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.offerCount > 1
      ? _c(
          "a",
          {
            attrs: {
              href: "javascript:void(0);",
              "data-bs-toggle": "popover",
              title: "Verfügbare Sonderangebote",
              "data-bs-trigger": "click",
              "data-bs-placement": "top",
              "data-bs-html": "true",
              "data-bs-content": _vm.getOfferText
            }
          },
          [
            _c("span", { staticClass: "offer-left" }, [
              _vm._v(_vm._s(_vm.voMsg("search.offers.available")))
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.offerCount <= 1
      ? _c("span", [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.getOfferText) } })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }