import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
		showSearchBox: false,
		showTeleBox: false,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},

		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		},

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {
		scrollToTop: function () {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		toggleSearchbox: function (event) {
			this.showSearchBox = !this.showSearchBox;
			if (this.showSearchBox) {
				this.showTeleBox = false;
			}

		},
		toggleTelebox: function (event) {
			this.showTeleBox = !this.showTeleBox;
			if (this.showTeleBox) {
				this.showSearchBox = false;
			}

		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};