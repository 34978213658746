<template>
    <div>
        <ul class="nav nav-tabs"
            id="myTab"
            role="tablist">
            <li v-for="(year, index) in this.getYears"
                class="nav-item"
                role="presentation">
                <button class="nav-link"
                    :class="index == 0 ? 'active': ''"
                    :id="getYearButtonId(year)"
                    data-bs-toggle="tab"
                    :data-bs-target="getTarget(year)"
                    type="button"
                    role="tab"
                    :aria-controls="getTabId(year)"
                    :aria-selected="index == 0 ? true : false">{{year}}</button>
            </li>

        </ul>
        <div class="tab-content"
            id="priceTabContent">
            <div v-for="(year, index) in this.getYears"
                class="tab-pane fade"
                :class="index == 0 ? 'show active' : ''"
                :id="getTabId(year)"
                role="tabpanel"
                :aria-labelledby="getTabAreaLabel(year)">

                <table class="table pricelist-table table-hover">

                    <tbody v-for="sg in priceArray">
                        <!-- <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                            v-if="sg.service.type=='RENT'">
                            <td>
                                {{sg.service.name}}
                                <small>
                                   <span v-if="sg.nightRange">
                                     <span v-if="sg.nightRange.max < 99">
  														{{sg.nightRange.min}} - {{sg.nightRange.max}} 
                                       <span v-if="sg.nightRange.max > 1">{{voMsg('tpl.text.nights')}}</span>
                                       <span v-if="sg.nightRange.max == 1">{{voMsg('tpl.text.night')}}</span>
  												 </span> 
                                      <span v-if="sg.nightRange.max > 99">
  														 {{voMsg('tpl.startfrom')}} {{sg.nightRange.min}} {{voMsg('tpl.text.nights')}}
  												 </span> 
  												</span>
                                    
                                  <span v-if="sg.guestRange">
                                      <span v-if="sg.guestRange.max > 99">
                                         {{voMsg('tpl.startfrom')}} {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
                                      <span v-if="sg.guestRange.max < 99">
                                        {{sg.guestRange.min}} - {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
  												</span>
											</small>
                                <span v-if="! sg.service.mandatory">({{voMsg('tpl.text.optional')}})</span>
                                <span v-if="sg.service.mandatory"><sup>*</sup></span>
                            </td>

                            <td class="text-end">
                                <div>
                                    <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                    <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                    <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                    <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                    <span v-if="sg.service.perChild"><span>{{voMsg('tpl.perChild')}}</span></span>
                                    <span v-if="sg.service.perAdult"><span>{{voMsg('tpl.perAdult')}}</span></span>
                                </div>
                            </td>
                            <td class="text-end price">
                                <span class="text-nowrap"
                                    v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>

                        </tr> 
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="isDateInRange(price.fromdate,year) && sg.service.type=='RENT'">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>

                            <td class="text-end calc">
                                <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                <span v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                            <td class="text-end price">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                        </tr> -->

                    </tbody>
                    <tbody v-for="priceArr in taxArray">
                        <span v-for="sg in priceArr">
                        <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                            v-if="sg.service.type=='VTAX'">
                            <td>
                                {{sg.service.name}}
                                <small>
                                   <span v-if="sg.nightRange">
                                     <span v-if="sg.nightRange.max < 99">
  														{{sg.nightRange.min}} - {{sg.nightRange.max}} 
                                       <span v-if="sg.nightRange.max > 1">{{voMsg('tpl.text.nights')}}</span>
                        <span v-if="sg.nightRange.max == 1">{{voMsg('tpl.text.night')}}</span>
                        </span>
                        <span v-if="sg.nightRange.max > 99">
  														 {{voMsg('tpl.startfrom')}} {{sg.nightRange.min}} {{voMsg('tpl.text.nights')}}
  												 </span>
                        </span>

                        <span v-if="sg.guestRange">
                                      <span v-if="sg.guestRange.max > 99">
                                         {{voMsg('tpl.startfrom')}} {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
                        <span v-if="sg.guestRange.max < 99">
                                        {{sg.guestRange.min}} - {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
                        </span>
                        </small>
                        <span v-if="! sg.service.mandatory">({{voMsg('tpl.text.optional')}})</span>
                        <span v-if="sg.service.mandatory"><sup>*</sup></span>
                        </td>

                        <td class="text-end">
                            <div>
                                <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                <span v-if="sg.service.perChild"><span>{{voMsg('tpl.perChild')}}</span></span>
                                <span v-if="sg.service.perAdult"><span>{{voMsg('tpl.perAdult')}}</span></span>
                            </div>
                        </td>
                        <td class="text-end price">
                            <span class="text-nowrap"
                                v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span>
                            <span class="text-nowrap"
                                v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                        </td>

                        </tr>
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="isDateInRange(price.fromdate,year) && sg.service.type=='VTAX'">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>

                            <td class="text-end calc">
                                <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                <span v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                            <td class="text-end price">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                        </tr>
                        </span>
                    </tbody>

                    <tbody v-for="sg in priceArray">
                        <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                            v-if="sg.service.type!='VTAX' && sg.service.type!='RENT'">
                            <td>
                                {{sg.service.name}}
                                <small>
                                   <span v-if="sg.nightRange">
                                     <span v-if="sg.nightRange.max < 99">
  														{{sg.nightRange.min}} - {{sg.nightRange.max}} 
                                       <span v-if="sg.nightRange.max > 1">{{voMsg('tpl.text.nights')}}</span>
                                       <span v-if="sg.nightRange.max == 1">{{voMsg('tpl.text.night')}}</span>
  												 </span> 
                                      <span v-if="sg.nightRange.max > 99">
  														 {{voMsg('tpl.startfrom')}} {{sg.nightRange.min}} {{voMsg('tpl.text.nights')}}
  												 </span> 
  												</span>
                                    
                                  <span v-if="sg.guestRange">
                                      <span v-if="sg.guestRange.max > 99">
                                         {{voMsg('tpl.startfrom')}} {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
                                      <span v-if="sg.guestRange.max < 99">
                                        {{sg.guestRange.min}} - {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
  												</span>
											</small>
                                <span v-if="! sg.service.mandatory">({{voMsg('tpl.text.optional')}})</span>
                                <span v-if="sg.service.mandatory"><sup>*</sup></span>
                            </td>

                            <td class="text-end">
                                <div>
                                    <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                    <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                    <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                    <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                    <span v-if="sg.service.perChild"><span>{{voMsg('tpl.perChild')}}</span></span>
                                    <span v-if="sg.service.perAdult"><span>{{voMsg('tpl.perAdult')}}</span></span>
                                </div>
                            </td>
                            <td class="text-end price">
                                <span class="text-nowrap"
                                    v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>

                        </tr>
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="isDateInRange(price.fromdate,year) && sg.service.type!='VTAX' && sg.service.type!='RENT'">
                            <td class="subinfo">
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <br/><small>{{price.season}}</small>
                            </td>

                            <td class="text-end calc">
                                <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                <span v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                            <td class="text-end price">
                                <span class="text-nowrap"
                                    v-if="price.value">{{getCalculatedPrice(price.value)}}</span>
                                <span class="text-nowrap"
                                    v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>


        </div>

        <div class="hint"><sup>*</sup>{{voMsg('tpl.text.priceObligatory')}}</div>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			taxArray: {},
    			years: Array,
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number,
    			rentalPrices: Array,
    			mandatoryPrices: Array,
    			optionalPrices: Array,
    			surchargePrices: Array
    		};
    	},
    	mounted: function() {
    		//console.log('this.pricelist::' + this.pricelist);
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;
    		//console.log('pricelist::::' + pricelist);
    		var price27029 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27029 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		var price27042 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27042 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		var price27049 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27049 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		var price27918 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27918 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		var price27915 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27915 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		var price27066 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27066 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		var price27917 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27917 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		var price27913 = pricelist.filter((priceListElement) => {
    			return priceListElement.service.type && priceListElement.service.type === 'VTAX' && priceListElement.service._id === 27913 && !priceListElement.service.surcharge && priceListElement.lines;
    		});
    		this.taxArray[0] = price27029;
    		this.taxArray[1] = price27042;
    		this.taxArray[2] = price27049;
    		this.taxArray[3] = price27918;
    		this.taxArray[4] = price27915;
    		this.taxArray[5] = price27066;
    		this.taxArray[6] = price27917;
    		this.taxArray[7] = price27913;

    		/*this.taxArray[1] = price;
    		console.log('pricelist::::' + this.taxArray);*/
    	},
    	methods: {
    		getYearButtonId: function(year) {
    			return 't' + year + '-tab';
    		},
    		getTarget: function(year) {
    			return "#t" + year;
    		},
    		getTabId: function(year) {
    			return "t" + year;
    		},
    		getTabAreaLabel: function(year) {
    			return "t" + year + "-tab";
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice: function(price) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price;
    				calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}
    			return calcPrice;
    		},
    		getCalculatedMandatoryPrice: function() {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			calcPrice = Number((this.priceBookingFee + this.priceFinalCleaning) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";

    			return calcPrice;
    		},
    		/*	isDateInRange: function(fromdate, year) {
    				var fromYear = parseInt(fromdate.substring(0, 4));
    				//console.log('fromYear::', fromYear, "Year::", year);
    				if (fromYear == year) {
    					return true;
    				}
    				return false;
    			}*/
    		isDateInRange: function(tilldate, year) {
    			if (!tilldate) {
    				return true;
    			}
    			var tillYear = parseInt(tilldate.substring(0, 4));
    			if (tillYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {
    		/*	getYears: function() {
    				var pricelist = JSON.parse(this.pricelist);

    				var years = [];

    				var i, j;
    				for (i = 0; i < pricelist.length; i++) {
    					for (j = 0; j < pricelist[i].lines.length; j++) {
    						if (pricelist[i].lines[j].fromdate) {
    							var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    							if (years.indexOf(year) < 0) {
    								years.push(year);
    							}
    						}
    					}
    				}
    				return years;
    			}
    		}*/
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			console.log('Pricelist::', pricelist);
    			var years = [];
    			var today = new Date();
    			var currentYear = today.getFullYear();
    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].tilldate) {
    						var year = pricelist[i].lines[j].tilldate.substring(0, 4);
    						console.log('year::' + year);
    						console.log('currentYear::' + currentYear);
    						if (years.indexOf(year) < 0 && parseInt(year) <= (parseInt(currentYear) + 1)) {
    							console.log('adding year');
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>